import React, { Component } from 'react';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import axios from "axios";

var datum = new Date();



class Form extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: "",
            name2: "",
            lastname: "",
            lastname2: "",
            email: "",
            email2: "",
            phone: "",
            phone2: "",
            street: "",
            street2: "",
            city: "",
            city2: "",
            postcode: "",
            postcode2: "",
            state: "Slovenská republika",
            state2: "",
            date_of_birh: "",
            date_of_birh2: "",
            billing_email: "",
            billing_email2: "",
            time_billing: 1,
            time_billing2: "",
            start_date: "",
            start_date2: "",
            notify_email: "",
            notify_email2: "",
            resending: 1,
            picking: 1,
            message: "",
            meet: "Nechcem sa vyjadriť",
            zivnost: "Nie",
            coupon: "",
            form: 1,
            osobaprice: 99,
            validatedDate: false,
            vek: "",
            allprice: 99,
            gdpr: false,
            vop: false,
            birth_lastname: "",
            birth_lastname2: "",
            id_number: "",
            id_number2: "",
            statne_obcianstvo: "Slovenské",
            statne_obcianstvo2: "",
            miesto_narodenia: "",
            showstat: false,
            miesto_narodenia2: "",
            cislo_obcianskeho: "",
            cislo_obcianskeho2: "",
            platnost_obcianskeho: "",
            platnost_obcianskeho2: "",
            vydanie_obcianskeho: "",
            vydanie_obcianskeho2: "",
            zivnostSidlo: false,
            ico: "",
            ico2: "",
            loader: 0,
            gdpr3: false,
            gdpr4: "",
        }
        this.handleCisloObcianskeho = this.handleCisloObcianskeho.bind(this);
        this.handlePlatnostObcianskeho = this.handlePlatnostObcianskeho.bind(this);
        this.handleVydanieObcianskeho = this.handleVydanieObcianskeho.bind(this);
        this.handleMiestoNarodenia = this.handleMiestoNarodenia.bind(this);
        this.handleStatneObcianstvo = this.handleStatneObcianstvo.bind(this);
        this.handleIdNumber = this.handleIdNumber.bind(this);
        this.handleBirthLastname = this.handleBirthLastname.bind(this);
        this.recount = this.recount.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleCity = this.handleCity.bind(this);
        this.handleLastname = this.handleLastname.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleStreet = this.handleStreet.bind(this);
        this.handlePicking = this.handlePicking.bind(this)
        this.handleGdpr = this.handleGdpr.bind(this);
        this.handleVop = this.handleVop.bind(this);
        this.handlePostcode = this.handlePostcode.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleBillingEmail = this.handleBillingEmail.bind(this);
        this.handleTimeBilling = this.handleTimeBilling.bind(this);
        this.handleNotifyEmail = this.handleNotifyEmail.bind(this);
        this.handleResending = this.handleResending.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.handleMeet = this.handleMeet.bind(this);
        this.handleZivnost = this.handleZivnost.bind(this);
        this.handleCoupon = this.handleCoupon.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleSubmitFirst = this.handleSubmitFirst.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateOfBirth = this.handleDateOfBirth.bind(this);
        this.handleIco = this.handleIco.bind(this);
        this.handleZivnostSidlo = this.handleZivnostSidlo.bind(this);
        this.handleGdpr2 = this.handleGdpr2.bind(this);
        this.handlesel = this.handlesel.bind(this);
    }

    handlesel(event){
        if(event.target.value == 'ine'){
            this.setState({
                showstat: true,
                state: "",
            })
        }
        else{
            this.setState({
                showstat: false,
                state: "",
            })
        }
    }
    handleGdpr2(event){
        this.setState({gdpr3: !this.state.gdpr3})
    }
    handleIco(event){
        this.setState({ico: event.target.value})
    }
    handleZivnostSidlo(event){
        this.setState({zivnostSidlo: !this.state.zivnostSidlo}, () => this.recount())
    }

    handleVydanieObcianskeho(event){
        this.setState({vydanie_obcianskeho: event.target.value});
    }
    handlePlatnostObcianskeho(event){
        this.setState({platnost_obcianskeho: event.target.value});
    }
    handleCisloObcianskeho(event){
        this.setState({cislo_obcianskeho: event.target.value});
    }

    handleMiestoNarodenia(event){
        this.setState({miesto_narodenia: event.target.value})
    }
    handleStatneObcianstvo(event){
        this.setState({statne_obcianstvo: event.target.value})
    }
    handleIdNumber(event){
        this.setState({id_number: event.target.value})
    }
    handleBirthLastname(event){
        this.setState({birth_lastname: event.target.value})
    }
    handleGdpr(event){
        this.setState({gdpr: !this.state.gdpr})
    }
    handleVop(event){
        this.setState({vop: !this.state.vop})
    }

    recount(){

        var osoba = this.state.osobaprice;
        var prep_posta = this.state.resending;
        var preberanie = this.state.picking;
        var zivnostSidlo = this.state.zivnostSidlo;

        if(zivnostSidlo == true){
            zivnostSidlo = 40;
        }
        else{
            zivnostSidlo = 0;
        }

        console.log(osoba);
        console.log(prep_posta);
        console.log(preberanie);

        osoba = osoba * this.state.time_billing;

        if(preberanie == 1){
            preberanie = 0;
        }
        else{
            preberanie = 8.90;
        }
        if(prep_posta == 1){
            prep_posta = 0;
        }
        else if(prep_posta == 2){
            prep_posta = 7.90 * 12 * this.state.time_billing;

        }
        else if(prep_posta == 3){
            prep_posta = 4.90 * 12 * this.state.time_billing;
        }
        else if(prep_posta == 4){
            prep_posta = 2.90 * 12 * this.state.time_billing;
        }

        console.log(osoba);
        console.log(prep_posta);
        console.log(preberanie);
        console.log(zivnostSidlo);


        var allprice1 = osoba + prep_posta + preberanie + zivnostSidlo;

        allprice1 = Math.round(allprice1* 100) / 100;

        this.setState({
            allprice: allprice1,
        })

    }
     dateDiffInYears(dateold, datenew) {
        var ynew = datenew.getFullYear();
        var mnew = datenew.getMonth();
        var dnew = datenew.getDate();
        var yold = dateold.getFullYear();
        var mold = dateold.getMonth();
        var dold = dateold.getDate();
        var diff = ynew - yold;
        if (mold > mnew) diff--;
        else {
            if (mold == mnew) {
                if (dold > dnew) diff--;
            }
        }
        return diff;
    }

    handleDateOfBirth(event){
        var datum = new Date(event.target.value);
        if(event.target.value.length == 10){
            var dt1   = parseInt(event.target.value.substring(0,2));
            var mon1  = parseInt(event.target.value.substring(3,5));
            var yr1   = parseInt(event.target.value.substring(6,10));
            var date1 = new Date(yr1, mon1-1, dt1);

            if(this.dateDiffInYears(date1, new Date()) == 0){
                this.setState({osobaprice: 0,validatedDate: true, vek: "0"}, () => this.recount())

            }
            else if(this.dateDiffInYears(date1, new Date()) < 6 && this.dateDiffInYears(date1, new Date()) > 0 ){
                this.setState({osobaprice: 0,validatedDate: true, vek: this.dateDiffInYears(date1, new Date())}, () => this.recount())
            }
            else if(this.dateDiffInYears(date1, new Date()) < 15 && this.dateDiffInYears(date1, new Date()) > 0 ){
                this.setState({osobaprice: 49,validatedDate: true, vek: this.dateDiffInYears(date1, new Date())}, () => this.recount())
            }
            else if(this.dateDiffInYears(date1, new Date()) >= 15 && this.dateDiffInYears(date1, new Date()) <=100 && this.dateDiffInYears(date1, new Date()) > 0 ){
                this.setState({osobaprice: 99,validatedDate: true, vek: this.dateDiffInYears(date1, new Date())}, () => this.recount())
            }
            else{
                this.setState({validatedDate: false, vek: ""}, () => this.recount())
            }

        }
        else{
            this.setState({validatedDate: false, vek: ""}, () => this.recount())
        }

        //alert(this.dateDiffInYears(event.target.value, new Date()))
        this.setState({date_of_birh: event.target.value})
    }

    handleSubmit(event){

        if(this.state.cislo_obcianskeho == ""){
            this.setState({cislo_obcianskeho2: "Toto pole je povinné"})
            event.preventDefault();
        }
        else{
            this.setState({cislo_obcianskeho2: ""})
        }

        if(this.state.platnost_obcianskeho == ""){
            this.setState({platnost_obcianskeho2: "Toto pole je povinné"})
            event.preventDefault();
        }
        else{
            this.setState({platnost_obcianskeho2: ""})
        }
        if(this.state.vydanie_obcianskeho == ""){
            this.setState({vydanie_obcianskeho2: "Toto pole je povinné"})
            event.preventDefault();
        }
        else{
            this.setState({vydanie_obcianskeho2: ""})
        }

        if(this.state.id_number == ""){
            this.setState({id_number2: "Toto pole je povinné"})
            event.preventDefault();
        }
        else{
            this.setState({id_number2: ""})
        }
        if(this.state.miesto_narodenia == ""){
            this.setState({miesto_narodenia2: "Toto pole je povinné!"})
            event.preventDefault();
        }
        else{
            this.setState({miesto_narodenia2: ""})
        }

        if(this.state.name == ""){
            this.setState({name2: "Toto pole je povinné!"})
            event.preventDefault();

        }

        else{
            this.setState({name2: ""})
        }
        if(this.state.statne_obcianstvo == ""){
            this.setState({statne_obcianstvo2: "Toto pole je povinné"});
            event.preventDefault();
        }
        else{
            this.setState({statne_obcianstvo2: ""});
        }
        if(this.state.birth_lastname == ""){
            this.setState({birth_lastname2: "Toto pole je povinné"})
            event.preventDefault();
        }
        else{
            this.setState({birth_lastname2: ""})
        }
        if(this.state.lastname == ""){
            this.setState({lastname2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({lastname2: ""})
        }
        if(this.state.email == ""){
            this.setState({email2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({email2: ""})
        }
        if(this.state.phone == ""){
            this.setState({phone2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({phone2: ""})
        }
        if(this.state.street == ""){
            this.setState({street2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({street2: ""})
        }
        if(this.state.city == ""){
            this.setState({city2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({city2: ""})
        }
        if(this.state.postcode == ""){
            this.setState({postcode2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({postcode2: ""})
        }
        if(this.state.state == ""){
            this.setState({state2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({state2: ""})
        }
        if(this.state.billing_email == ""){
            this.setState({billing_email2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({billing_email2: ""})
        }
        if(this.state.notify_email == ""){
            this.setState({notify_email2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({notify_email2: ""})
        }
        if(this.state.validatedDate == false){
            this.setState({date_of_birh2: "Dátum je zle zadaný - tvar 01.01.1990"})
            event.preventDefault();
        }
        else{
            this.setState({date_of_birh2: ""})
        }
        if(this.state.gdpr == false){
            this.setState({gdpr2: "Toto pole je povinné!"})
            event.preventDefault();
        }
        else{
            this.setState({gdpr2: ""})
        }
        if(this.state.vop == false){
            this.setState({vop2: "Toto pole je povinné!"})
            event.preventDefault();
        }
        else{
            this.setState({vop2: ""})
        }
        if(this.state.zivnostSidlo == true && this.state.ico == "" ){
            this.setState({ico2: "Toto pole je povinné!"})
            event.preventDefault();
        }
        else{
            this.setState({ico2: ""})
        }
        if(this.state.phone == "" || this.state.email == "" || this.state.name == "" || this.state.lastname == "" || this.state.street == "" || this.state.postcode == "" || this.state.city == "" || this.state.state == "" || this.state.billing_email == "" || this.state.notify_email == ""  || this.state.validatedDate == false || this.state.gdpr == false || this.state.vop == false
        || this.state.birth_lastname == "" || this.state.id_number == "" || this.state.miesto_narodenia == "" || this.state.cislo_obcianskeho =="" || this.state.vydanie_obcianskeho == "" || this.state.platnost_obcianskeho == "" || this.state.statne_obcianstvo == "" || (this.state.zivnostSidlo == true && this.state.ico == "") ){
            NotificationManager.warning('', 'Niektoré polia nie sú vyplnené', 3000);
            event.preventDefault();
            return;
        }

        var json = {
            name: this.state.name,
            lastname: this.state.lastname,
            date_of_birth: this.state.date_of_birh,
            email: this.state.email,
            phone: this.state.phone,
            street: this.state.street,
            city: this.state.city,
            postcode: this.state.postcode,
            state: this.state.state,
            billing_email: this.state.billing_email,
            notify_email: this.state.notify_email,
            start_date: this.state.start_date,
            time_billing: this.state.time_billing,
            preberanie: this.state.picking,
            preposielanie: this.state.resending,
            meet: this.state.meet,
            zivnost: this.state.zivnost,
            sprava: this.state.message,
            gdpr: this.state.gdpr,
            vop: this.state.vop,
            birth_lastname: this.state.birth_lastname,
            id_number: this.state.id_number,
            miesto_narodenia: this.state.miesto_narodenia,
            cislo_obcianskeho: this.state.cislo_obcianskeho,
            vydanie_obcianskeho: this.state.vydanie_obcianskeho,
            platnost_obcianskeho: this.state.platnost_obcianskeho,
            statne_obcianstvo: this.state.statne_obcianstvo,
            sidlo: this.state.zivnostSidlo,
            ico: this.state.ico,
            allprice: this.state.allprice,
            vek: this.state.vek,

        }
        var self = this;
        this.setState({loader: 1});
        axios.post("https://www.mojekonto.sk/api/firmapreludi", JSON.stringify(json)).then(data =>
            console.log(data),
            self.setState({loader: 0})
        );

        console.log(JSON.stringify(json));
        event.preventDefault();
        this.setState({form: 3});


    }
    handleSubmitFirst(event){
        if(this.state.name == ""){
            this.setState({name2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({name2: ""})
        }
        if(this.state.lastname == ""){
            this.setState({lastname2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({lastname2: ""})
        }
        if(this.state.email == ""){
            this.setState({email2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({email2: ""})
        }
        if(this.state.phone == ""){
            this.setState({phone2: "Toto pole je povinné!"})
            event.preventDefault();

        }
        else{
            this.setState({phone2: ""})
        }
        if(this.state.gdpr3 == false){
            this.setState({gdpr4: "Toto pole je povinné!"})
            event.preventDefault();
        }
        else{
            this.setState({gdpr4: ""})
        }
        if(this.state.phone == "" || this.state.email == "" || this.state.name == "" || this.state.lastname == "" || this.state.gdpr3 == false){
            NotificationManager.warning('', 'Niektoré polia nie sú vyplnené', 3000);
            return;
        }
        if(this.state.notify_email == ""){
            this.setState({notify_email: this.state.email});
        }
        if(this.state.billing_email == ""){
            this.setState({billing_email: this.state.email})
        }

        var datas = {
            name: this.state.name,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone
        }

        axios.post("https://www.mojekonto.sk/api/firmapreludi/attempt", JSON.stringify(datas)).then(res=>
            console.log("ok"),
        );

        this.setState({form: 2})
        event.preventDefault();
    }
    handlePicking(event){
        this.setState({picking: event.target.value}, () => this.recount())

    }
    handleStartDate(event){
        this.setState({start_date: event.target.value})
    }
    handleCity(event){
        this.setState({city: event.target.value})
    }
    handlePhone(event){
        this.setState({phone: event.target.value})
    }
    handleName(event){
        this.setState({name: event.target.value})
    }
    handleLastname(event){
        this.setState({lastname: event.target.value})
    }
    handleEmail(event){
        this.setState({email: event.target.value})
    }
    handleStreet(event){
        this.setState({street: event.target.value})
    }

    handlePostcode(event){
        this.setState({postcode: event.target.value})
    }
    handleState(event){
        this.setState({state: event.target.value})
    }
    handleBillingEmail(event){
        this.setState({billing_email: event.target.value})
    }
    handleNotifyEmail(event){
        this.setState({notify_email: event.target.value})
    }
    handleResending(event){
        this.setState({resending: event.target.value}, () => this.recount())

    }
    handleMessage(event){
        this.setState({message: event.target.value})
    }
    handleMeet(event){
        this.setState({meet: event.target.value})
    }
    handleCoupon(event){
        this.setState({coupon: event.target.value})
    }
    handleZivnost(event){
        this.setState({zivnost: event.target.value})
    }
    handleTimeBilling(event){
        this.setState({time_billing: event.target.value}, () => this.recount())

    }


    render(){
        return(
            <div className="form blue">
                <NotificationContainer/>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row ods">
                            <div className="col-md-12">
                                {this.state.form == 1 ?
                                <h2 className="headline center">Objednávka trvalého pobytu</h2>
                                    : null}
                                {this.state.form == 2 ?
                                    <h2 className="headline center">Objednávka trvalého pobytu</h2>
                                    : null}

                            </div>
                        </div>
                        {this.state.form == 1 ?
                            <div className="row ods">
                                <div className="col-md-12">
                                    <form onSubmit={this.handleSubmitFirst}>
                                        <h4 className="headline mini">Kontaktné údaje objednávateľa</h4>
                                        <br/>
                                        <div className="form-row">
                                            <div className="col-md-6">

                                            <label>Meno: *</label>
                                            <input type="text" placeholder="Meno" onChange={this.handleName} value={this.state.name} className="form-control"/>

                                            <span className="error">{this.state.name2}</span>
                                            </div>

                                        <div className="col-md-6">
                                            <label>Priezvisko: *</label>
                                            <input type="text" placeholder="Priezvisko" onChange={this.handleLastname} value={this.state.lastname} className="form-control"/>

                                            <span className="error">{this.state.lastname2}</span>
                                        </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                            <label>E-Mail: *</label>
                                            <input type="email" placeholder="E-Mail" onChange={this.handleEmail} value={this.state.email} className="form-control"/>

                                            <span className="error">{this.state.email2}</span>
                                            </div>

                                        <div className="col-md-6">
                                            <label>Tel. číslo: *</label>
                                            <input type="tel" placeholder="Tel. číslo" onChange={this.handlePhone} value={this.state.phone} className="form-control"/>

                                            <span className="error">{this.state.phone2}</span>

                                        </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <br/>
                                                <input type="checkbox" onChange={this.handleGdpr2} /> Súhlasím so spracovaním osobných údajov v súlade s nariadením GDPR o ochrane osobných údajov (<a target="_blank" href="https://www.firmaprefirmy.sk/ochrana-sukromia">Viac informácií.</a>) <br/>
                                                <span className="error">{this.state.gdpr4}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 centerize">
                                                <br/>

                                                <input type="submit" value="Pokračovať" className="btn btn-primary butonsend" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            : null
                        }
                        {this.state.form == 2 ?
                        <div className="row ods">

                            <form onSubmit={this.handleSubmit} className="stovka">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="headline mini">Kontaktné údaje objednávateľa</h4>
                                        <br/>
                                        <div className="form-group">
                                            <label>Meno: *</label>
                                            <input placeholder="Meno" type="text" onChange={this.handleName} value={this.state.name} className="form-control"/>

                                            <span className="error">{this.state.name2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Priezvisko: *</label>
                                            <input placeholder="Priezvisko" type="text" onChange={this.handleLastname} value={this.state.lastname} className="form-control"/>

                                            <span className="error">{this.state.lastname2}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>Dátum narodenia: * {this.state.vek != "" ? <span>{this.state.vek} rokov</span> : null}</label>
                                            <input  type="text" onChange={this.handleDateOfBirth} placeholder="dd.mm.YYYY" value={this.state.date_of_birh} className="form-control"/>

                                            <span className="error">{this.state.date_of_birh2}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>E-Mail: *</label>
                                            <input placeholder="E-Mail" type="email" onChange={this.handleEmail} value={this.state.email} className="form-control"/>

                                            <span className="error">{this.state.email2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Tel. číslo: *</label>
                                            <input placeholder="09XX XXX XXX" type="tel" onChange={this.handlePhone} value={this.state.phone} className="form-control"/>

                                            <span className="error">{this.state.phone2}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <h4 className="headline mini">Súčasný trvalý pobyt</h4>
                                        <br/>
                                        <div className="form-group">
                                            <label>Ulica + číslo: *</label>



                                            <input placeholder="Ulica + číslo" type="text" onChange={this.handleStreet} value={this.state.street} className="form-control"/>

                                            <span className="error">{this.state.street2}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>Mesto: *</label>
                                            <input placeholder="Mesto" type="text" onChange={this.handleCity} value={this.state.city} className="form-control"/>

                                            <span className="error">{this.state.city2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>PSČ: *</label>
                                            <input placeholder="PSČ" type="text" onChange={this.handlePostcode} value={this.state.postcode} className="form-control"/>

                                            <span className="error">{this.state.postcode2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Štát: *</label>

                                            <select onChange={this.handlesel} className="form-control">
                                                <option value="sr">Slovenská republika</option>
                                                <option value="ine">Iné</option>
                                            </select>
                                            <br/>

                                            <input placeholder="Štát" type="text" onChange={this.handleState} value={this.state.state} className={this.state.showstat == true ? "form-control" : "hide"}/>


                                            <span className="error">{this.state.state2}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-6 ods">
                                        <h4 className="headline mini">Údaje o objednávateľovi</h4>
                                        <div className="form-group">
                                            <label>Rodné priezvisko *</label>
                                            <input placeholder="Rodné priezvisko" className="form-control" type="text" value={this.state.birth_lastname} onChange={this.handleBirthLastname}/>
                                            <span className="error">{this.state.birth_lastname2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Rodné číslo *</label>
                                            <input placeholder="Rodné číslo" className="form-control" type="text" value={this.state.id_number} onChange={this.handleIdNumber}/>
                                            <span className="error">{this.state.id_number2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Štátne občianstvo *</label>
                                            <input placeholder="Štátne občianstvo" className="form-control" type="text" value={this.state.statne_obcianstvo} onChange={this.handleStatneObcianstvo}/>
                                            <span className="error">{this.state.statne_obcianstvo2}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Miesto narodenia *</label>
                                            <input placeholder="Miesto narodenia" className="form-control" type="text" value={this.state.miesto_narodenia} onChange={this.handleMiestoNarodenia}/>
                                            <span className="error">{this.state.miesto_narodenia2}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ods">
                                        <h4 className="headline mini">Údaje o OP</h4>
                                        <div className="form-group">
                                            <label>Číslo OP *</label>
                                            <input placeholder="Číslo OP" className="form-control" type="text" value={this.state.cislo_obcianskeho} onChange={this.handleCisloObcianskeho}/>
                                            <span className="error">{this.state.cislo_obcianskeho2}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>Platnosť OP *</label>
                                            <input placeholder="Platnosť OP" className="form-control" type="text" value={this.state.platnost_obcianskeho} onChange={this.handlePlatnostObcianskeho}/>
                                            <span className="error">{this.state.platnost_obcianskeho2}</span>
                                        </div>

                                        <div className="form-group">
                                            <label>OP vydal *</label>
                                            <input placeholder="OP vydal" className="form-control" type="text" value={this.state.vydanie_obcianskeho} onChange={this.handleVydanieObcianskeho}/>
                                            <span className="error">{this.state.vydanie_obcianskeho2}</span>
                                        </div>

                                    </div>


                                    <div className="col-md-6 ods">
                                        <h4 className="headline mini">Faktúru objednaných služieb zaslať na e-mail</h4>
                                        <div className="form-group">
                                            <label>E-Mail: *</label>
                                            <input placeholder="E-Mail" type="email" onChange={this.handleBillingEmail} value={this.state.billing_email} className="form-control"/>

                                            <span className="error">{this.state.billing_email2}</span>
                                        </div>
                                        <h4 className="headline mini">Notifikácie o prijatej pošte zasielať na e-mail</h4>
                                        <div className="form-group">
                                            <label>E-Mail: *</label>
                                            <input placeholder="E-Mail" type="email" onChange={this.handleNotifyEmail} value={this.state.notify_email} className="form-control"/>

                                            <span className="error">{this.state.notify_email2}</span>

                                        </div>
                                    </div>
                                    <div className="col-md-6 ods">
                                        <h4 className="headline mini">Doba viazanosti</h4>
                                        <div className="form-group">
                                            <label>Odo dňa: </label>
                                            <input type="text" placeholder="dd.mm.YYYY" onChange={this.handleStartDate} value={this.state.start_date} className="form-control"/>

                                            <span className="error">{this.state.start_date2}</span>
                                            <br/>
                                        </div>
                                        <div className="form-group">
                                            <input type="radio" onChange={this.handleTimeBilling} name="doba_viazanosti" checked={this.state.time_billing == 1 ? "checked" : null} value="1"/> 1 rok <br/>
                                            <input type="radio" onChange={this.handleTimeBilling} name="doba_viazanosti" checked={this.state.time_billing == 2 ? "checked" : null} value="2"/> 2 roky <br/>
                                            <input type="radio" onChange={this.handleTimeBilling} name="doba_viazanosti" checked={this.state.time_billing == 3 ? "checked" : null} value="3"/> 3 roky <br/>
                                        </div>
                                    </div>

                                    <div className="col-md-6 ">
                                        <h4 className="headline mini">Preposielanie pošty</h4>
                                        <div className="form-group">
                                            <select onChange={this.handleResending}  className="form-control">
                                                <option value={1}>budem si chodiť preberať osobne</option>
                                                <option value={2}>1x týždenne na adresu (+7,90 Eur / mesiac):</option>
                                                <option value={3}>1x každé 2 týždne na adresu (+4,90 Eur / mesiac):</option>
                                                <option value={4}>1x mesačne na adresu (+2,90 Eur / mesiac):</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <h4 className="headline mini">Preberanie doporučenej pošty</h4>
                                        <div className="form-group">
                                            <select onChange={this.handlePicking} className="form-control">
                                                <option value={1}>neželám si preberanie doporučenej pošty</option>
                                                <option value={2}>želám si preberanie doporučenej pošty (+8,90 Eur jednorázovo): </option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-6 ods">
                                        <h4 className="headline mini">Odkiaľ ste sa o nás dozvedeli</h4>
                                        <div className="form-group">
                                            <select onChange={this.handleMeet} className="form-control">
                                                <option value="Nechcem sa vyjadriť">Nechcem sa vyjadriť</option>
                                                <option value="Google">Google</option>
                                                <option value="Facebook">Facebook</option>
                                                <option value="Instagram">Instagram</option>
                                                <option value="Billboard">Billboard</option>
                                                <option value="Od znamého">Od znamého</option>
                                            </select>

                                        </div>
                                        <div className="form-group">
                                            <h4 className="headline mini">Máte založenú živnosť?</h4>
                                            <div className="form-group">
                                                <select onChange={this.handleZivnost} className="form-control">
                                                    <option value="Nie">Nie</option>
                                                    <option value="Áno">Áno</option>
                                                </select>

                                            </div>
                                            {this.state.zivnost == 'Áno' ?
                                                <div className="form-group">
                                                    <input type="checkbox" onChange={this.handleZivnostSidlo}/> Mám záujem o sídlo pre živnosť +40€ <br/>
                                                </div>
                                            : null}
                                            {this.state.zivnostSidlo == true ?
                                                <div className="form-group">
                                                    <label>IČO*</label>
                                                    <input type="text" className="form-control" onChange={this.handleIco} value={this.state.ico} />
                                                    <span className="error">{this.state.ico2}</span>
                                                </div>
                                            : null }
                                        </div>
                                    </div>
                                    <div className="col-md-6 ods">
                                        <h4 className="headline mini">Správa pre poskytovateľa</h4>
                                        <div className="form-group">
                                            <textarea placeholder="Vaša správa" onChange={this.handleMessage} value={this.state.message} className="form-control area"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <span className="headline mini">Cena: {this.state.allprice} €</span><br/><br/>
                                        <input type="checkbox" onChange={this.handleGdpr} /> Súhlasím so spracovaním osobných údajov v súlade s nariadením GDPR o ochrane osobných údajov (<a target="_blank" href="https://www.firmaprefirmy.sk/ochrana-sukromia">Viac informácií.</a>) <br/>
                                        <span className="error">{this.state.gdpr2}</span>
                                        <br/>

                                        <input type="checkbox" onChange={this.handleVop} /> Súhlasím s obchodnými podmienkami (<a href="https://www.firmaprefirmy.sk/obchodne-podmienky" target="_blank">Viac informácií.</a>)
                                        <span className="error">{this.state.vop2}</span>
                                        <br/>
                                    </div>
                                    <div className="col-md-6 centerize">
                                        <input type="submit" value="Odoslať objednávku" className="btn btn-primary butonsend" />
                                    </div>
                                </div>

                            </form>
                        </div>
                        : null }
                        {this.state.form == 3 ?
                            <div className="row ods order-success">
                                <div className="col-md-12">
                                    <h2 className="nadpis center">Ďakujeme za Vašu objednávku</h2>
                                    <p className="parag center">Na Váš E-Mail {this.state.email} sme Vám zaslali prihlasovacie údaje do systému a ďalšie pokyny</p>
                                </div>
                            </div>
                        : null}

                    </div>
                </div>
            </div>
        );
    }
}

export default Form;