import React, { Component } from 'react';
import Headline from "./Headline";
import Sections from "./Sections"
import Form from "./Form";
import Logo from "./logo_svetlé pozadie100.png"
import CookieConsent from "react-cookie-consent";
import LocationIc from "./location.svg";
import EmailIc from "./email.svg";
import PhoneIc from "./phone.svg";
import Domcek from "./domcek.svg";


import logo from './logo.svg';
import './App.css';

class App extends Component {

    render() {
        return (
            <div className="app">



                <Form/>




            </div>
        );
    }

}

export default App;
