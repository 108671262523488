import React, { Component } from 'react';
import Obrazok from "./comfortable-condo-condominium-2459.jpg";

var sectionStyle = {
    backgroundImage: `url(${Obrazok})`,

};

class Headline extends Component{

    constructor(props){
        super(props);
    }



    render(){
        return(
            <div className="container-flud mainobr" style={sectionStyle}>
                <div className="container">
                    <div className="row obrods">
                        <div className="col-md-12">
                            <h1 className="nadpisobr">Trvalý pobyt</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Headline;